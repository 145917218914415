*{
    margin:0;
    padding: 0;
    font-family: sans-serif;
    box-sizing: border-box;
}

.footer{
    padding: 30px 40px;
    width: 100%;
    background: #ecf2f7 ;
}

.footer .container{
    max-width:100%;
    display: flex;
    justify-content: space-between;
}

.footer .container .about{
    max-width: 30%;
    justify-content: space-between;
}

.footer .container .about .detail p{
    margin-top:17px;
    font-size: 16px;
    color: #010f1c;
}

.footer .container .about .detail .icon{
    margin-top:20px;
    max-width:45%;
    display: flex;
    justify-content: space-between;

}

.footer .container .about .detail .icon li{
    list-style: none;
    margin-left:20px;
    padding:10px 10px;
    box-shadow: rgba(0,0,0,0.24) 1.95px 1.95px 2.6px;
    cursor:pointer;
    transition: 0.5s;
}

.footer .container .about .detail .icon li:hover{
    background: #0989ff;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
}

.footer .container .account{
    margin-right: 90px;
}

.footer .container .account h3{
    font-size:32px;
    color: #010f1c;
}

.footer .container .account ul{
    /* list-style: none; */
    margin-top:10px;
    margin-left:20px;
}

.footer .container .account ul li{
    margin-top:5px;
    color: #010f1c;
    font-size: 14px;
    cursor: pointer;
    transition:0.5s;
}

.footer .container .account ul li:hover{
    color:#0989ff;
}

.footer .container .page{
    margin-right: 90px;
}

.footer .container .page h3{
    font-size:32px;
    color: #010f1c;
}

.footer .container .page ul{
    /* list-style: none; */
    margin-top:10px;
    margin-left:20px;
}

.footer .container .page ul li{
    margin-top:5px;
    color: #010f1c;
    font-size: 14px;
    cursor: pointer;
    transition:0.5s;
}

.footer .container .page ul li:hover{
    color:#0989ff;
}
