*{
    margin:0;
    padding:0;
    font-family: sans-serif;
    box-sizing: border-box;
}

.product_detail{
    margin-top:30px;
    padding: 30px 40px;
    width:100%;
    height:120%;
    position: fixed;
    background: rgba(0,0,0,0.24);
    z-index: 2;
    margin-top: -20%;
}

.product_detail .container{
    margin-top: 7%;
    margin-left: 7%;
    width: 1000px;
    height:550px;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(0,0,0,0.24) 0px 3px 8px;
}

.product_detail .container .closeBtn{
    margin-left: 97%;
    margin-right: 7%;
    border:none;
    outline: none;
    background: none;
    font-size: 22px;
    cursor: pointer;
}

.product_detail .container .product-box{
    max-width:100%;
    display: flex;
    padding:20px 30px;
    justify-content: space-between;
    margin-top:20px
}

.product_detail .container .product-box .img-box{
    padding:20px 30px;
    width:300px;
    height:300px;
    background: #f1f1f1;
}

.product_detail .container .product-box .img-box img{
    width:250px;
    height:250px;
}

.product_detail .container .product-box .detail{
    margin-left:30px;
    margin-top:10px;
}

.product_detail .container .product-box .detail h4{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 300;
    color:#9a9a9a;
    letter-spacing: 1px;
}

.product_detail .container .product-box .detail h2{
    margin-top:20px;
    font-size: 32px;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.product_detail .container .product-box .detail p{
    margin-top:10px;
    color: #4a4a4a;
}

.product_detail .container .product-box .detail h3{
    margin-top: 10px;
    font-size: 27px;
    color:#010f1c;
    letter-spacing: 1px;
}

.product_detail .container .product-box .detail button{
    margin-top:30px;
    padding: 16px 40px;
    color:#fff;
    background: #010f1c;
    border: none;
    outline:none;
    border-radius: 5px;
    transition: 0.5s;
    cursor: pointer;
    font-size: 20px;
}

.product_detail .container .product-box .detail button:hover{
    background: #0989ff;
}

.products{
    padding: 30px 40px;
}

.products h2{
    font-size: 42px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 400;
    color:#010f1c;
}

.products p{
    margin-top:10px;
    color:#9a9a9a;
}

.products .container{
    margin-top: 20px;
    max-width:100%;
    display: flex;
    justify-content: space-between;
}

.products .container .filter{
    padding:20px 30px;
}

.products .container .filter .categories h3{
    color:#010f1c;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 300;
}

.products .container .filter .categories ul{
    margin-top:10px;
}

.products .container .filter .categories ul li{
    list-style: none;
    margin-top:30px;
    color:#010f1c;
    cursor:pointer;
    transition: 0.5s;
}

.products .container .filter .categories ul li:hover{
    color:#0989ff;
}

.products .container .productBox {
    padding: 30px 40px;
    width: 100%;
    margin-left:10%;
}

.products .container .productBox .contant{
    display: flex;
    padding: 20px 10px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.products .container .productBox .contant .box{
    padding: 10px 20px;
    overflow: hidden;
    width: 300px;
    height: 367px;
    border: 1px solid #e2c0c0;
    margin-bottom: 20px;
    transition: 0.5s;
    align-content: center;
    cursor: pointer;
    border-radius: 5px;
}
.products .container .productBox .contant .box:hover{
    box-shadow: rgba(0,0,0,0.35) 0px 3px 8px;
}

.products .container .productBox .contant .box .img-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e2e0e0;
}

.products .container .productBox .contant .box .img-box img{
     width: 200px;
}
.products .container .productBox .contant .box .img-box .icon{
    z-index:1;
    position: relative;
    margin-bottom: 50%;
    display: flex;
    flex-direction:column;
}

.products .container .productBox .contant .box .img-box .icon li{
    list-style: none;
    font-size: 15px;
    background:#fff;
    color:#010f1c;
    padding: 10px;
    /* border: 1px solid #9a9a9a; */
    box-shadow: rgba(0,0,0,0.24) 0px 3px 8px;
    cursor:pointer;
    transition: 0.5s;
}

.products .container .productBox .contant .box .img-box .icon li:hover{
    background-color: #0989ff;
    color:#fff;
}

.products .container .productBox .contant .box .detail{
    margin-top: 10px;
}

.products .container .productBox .contant .box .detail p{
    color:#9a9a9a;
    font-size: 14px;
}

.products .container .productBox .contant .box .detail h3{
    font-size:16px;
    margin-top:5px;
    color:#010f1c;
    transition:0.5s;
}

.products .container .productBox .contant .box:hover .detail h3{
    color:#0989ff;
}