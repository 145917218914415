*{
    margin:0;
    padding: 0;
    font-family: sans-serif;
    box-sizing: border-box;
}

.contact{
    padding: 30px 40px;
    width: 100%;
    background: #f6f6f6;
}

.contact .container{
    padding: 10px 20px;
}

.contact .container h2{
    text-transform: uppercase;
    font-size: 32px;
    font-weight:300;
    color:#010f1c;
}

.contact .container .form{
    padding: 20px 30px;
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(0,0,0,0.24) 0px 3px 8px;
    margin-top: 30px;
}

.contact .container .form form{
    display: flex;
    flex-direction: column;
}

.contact .container .form form input{
    padding: 10px 30px;
    border: none;
    outline:none;
    background: none;
    border-bottom: 1px solid #010f1c;
    width: 350px;
    margin-top: 20px;
    margin-left:35%;
}

.contact .container .form form textarea{
    padding: 10px 30px;
    border: none;
    outline:none;
    background: none;
    border-bottom: 1px solid #010f1c;
    max-width: 350px;
    margin-top: 20px;
    resize:none;
    margin-left: 35%;
}

.contact .container .form form button{
    margin-top:30px;
    width:100px;
    padding: 10px 0px;
    border:none;
    outline:none;
    background: #010f1c;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    margin-left: 43.7%;
    cursor: pointer;
}

.contact .container .form form button:hover{
    background: #0989ff;
}