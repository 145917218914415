*{
    margin:0;
    padding:0;
    font-family: sans-serif;
    box-sizing: border-box;
}

.product_detail{
    margin-top:30px;
    padding: 30px 40px;
    width:100%;
    height:120%;
    position: fixed;
    background: rgba(0,0,0,0.24);
    z-index: 2;
    margin-top: -20%;
}

.product_detail .container{
    margin-top: 7%;
    margin-left: 7%;
    width: 1000px;
    height:550px;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(0,0,0,0.24) 0px 3px 8px;
}

.product_detail .container .closeBtn{
    margin-left: 97%;
    margin-right: 7%;
    border:none;
    outline: none;
    background: none;
    font-size: 22px;
    cursor: pointer;
}

.product_detail .container .product-box{
    max-width:100%;
    display: flex;
    padding:20px 30px;
    justify-content: space-between;
    margin-top:20px
}

.product_detail .container .product-box .img-box{
    padding:20px 30px;
    width:300px;
    height:300px;
    background: #f1f1f1;
}

.product_detail .container .product-box .img-box img{
    width:250px;
    height:250px;
}

.product_detail .container .product-box .detail{
    margin-left:30px;
    margin-top:10px;
}

.product_detail .container .product-box .detail h4{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 300;
    color:#9a9a9a;
    letter-spacing: 1px;
}

.product_detail .container .product-box .detail h2{
    margin-top:20px;
    font-size: 32px;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.product_detail .container .product-box .detail p{
    margin-top:10px;
    color: #4a4a4a;
}

.product_detail .container .product-box .detail h3{
    margin-top: 10px;
    font-size: 27px;
    color:#010f1c;
    letter-spacing: 1px;
}

.product_detail .container .product-box .detail button{
    margin-top:30px;
    padding: 16px 40px;
    color:#fff;
    background: #010f1c;
    border: none;
    outline:none;
    border-radius: 5px;
    transition: 0.5s;
    cursor: pointer;
    font-size: 20px;
}

.product_detail .container .product-box .detail button:hover{
    background: #0989ff;
}

.headings{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
}

.hero{
    padding: 30px 40px;
    width:100%;
    background:#e3edf6;
}

.hero .container{
    max-width: 100%;
    display: flex;
    justify-content: space-between;
}

.hero .container .detail{
    margin-top:8%;
    margin-left:30px;
}

.hero .container .detail h2{
    font-size:52px;
    color:#010f1c;
    margin-right:40%;
    margin-bottom:40px;
}
.hero .container .detail .link{
    
    padding:10px 20px;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    color:#010f1c;
    background: #fff;
    transition: 0.5s;
}

.hero .container .detail .link:hover{
    background-color: #0989ff;
    color:#fff;
}

.hero .container .img-box img{
    height: 450px;
    width: 700px;
}

.product_type{
    padding: 10px 40px;
    width:100%;
}

.product_type .container{
    max-width: 100%;
    display: flex;
    justify-content: space-between;
}

.product_type .container .box{
    padding: 20px 30px;
    margin-left: 10px;
}

.product_type .container .box .img_box{
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-color: #e8f4ff;
    overflow: hidden;
    display: flex;
    justify-content: center;
    cursor: pointer;

}

.product_type .container .box .img_box img{
    height: 120px;
    width: 120px;
    align-items: center;
    max-width: 100%;
    margin-top:38px;
    transition: 0.5s;
}

.product_type .container .box .img_box:hover img{
    transform: scale(1.15);
}

.product_type .container .box .detail{
    max-width: 100%;
    text-align: center;
    align-items: center;
}

.product_type .container .box .detail p{
    margin-top:10px;
    color: #9a9a9a;
}

.features{
    padding: 10px 70px;
    width:100%;
}

.features .container{
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    margin-top:20px;
}

.features .container .box{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #e8f4ff;
    border: 2px solid red;
    border-radius: 20px;
}
.features .container .box .icon p{
    margin-right: 10px;
    margin-top: 10px;
    font-size: 44px;
    color: red;
}

.features .container .box .detail h3{
    font-size: 26px;
    padding: 5px;
}

.features .container .box .detail p{
    font-size: 18px;
    padding: 5px;
    color:#303030;
}

.newlyLaunched {
    padding: 30px 40px;
    width: 100%;
}

.newlyLaunched .container{
    display: flex;
    padding: 20px 10px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.newlyLaunched .container .box{
    padding: 20px 20px;
    overflow: hidden;
    width: 300px;
    height: 367px;
    border: 1px solid #e2c0c0;
    margin-bottom: 20px;
    align-content: center;
    cursor: pointer;
    border-radius: 10px;
}
.newlyLaunched .container .box:hover{
    box-shadow: rgba(0,0,0,0.35) 0px 3px 8px;
}

.newlyLaunched .container .box .img-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e2e0e0;
}

.newlyLaunched .container .box .img-box img{
     width: 200px;
}
.newlyLaunched .container .box .img-box .icon{
    z-index:1;
    position: relative;
    margin-bottom: 50%;
    display: flex;
    flex-direction:column;
}

.newlyLaunched .container .box .img-box .icon li{
    list-style: none;
    font-size: 15px;
    background:#fff;
    color:#010f1c;
    padding: 10px;
    /* border: 1px solid #9a9a9a; */
    box-shadow: rgba(0,0,0,0.24) 0px 3px 8px;
    cursor:pointer;
    transition: 0.5s;
}

.newlyLaunched .container .box .img-box .icon li:hover{
    background-color: #0989ff;
    color:#fff;
}

.newlyLaunched .container .box .detail{
    margin-top: 10px;
}

.newlyLaunched .container .box .detail p{
    color:#9a9a9a;
    font-size: 14px;
}

.newlyLaunched .container .box .detail h3{
    font-size:16px;
    margin-top:5px;
    color:#010f1c;
    transition:0.5s;
}

.newlyLaunched .container .box:hover .detail h3{
    color:#0989ff;
}

.banner{
    width: 100%;
    padding: 20px 30px;
}

.banner .container{
    display: flex;
    justify-content: space-between;
    padding: 30px 40px;
    background-color: #0989ff;
    border-radius: 10px;
}

.banner .container .detail{
    margin-top: 6%;
    margin-left: 80px;
}

.banner .container .detail h4{
    color: #e3edf6;
    font-size: 18px;
    letter-spacing: 1px;
}

.banner .container .detail h3{
    margin-top:10px;
    font-size: 42px;
    margin-right:30%;
    color: #fff;
}

.banner .container .detail p{
    margin-top:20px;
    color:#fff;
    font-size: 32px;
    letter-spacing: 1.5px;
    font-weight: 600;
    margin-bottom: 30px;
}

.banner .container .detail .link{
    color: #e8f4ff;
    background: #010f1c;
    margin-top: 10px;
    padding:10px 30px;
    text-decoration: none;
    border-radius: 10px;
    transition: 0.5s;
}

.banner .container .detail .link:hover{
    background: #fff;
    color:#0989ff;
}

.banner .container .detail link{
    font-size: 30px;
}

.banner .container .img-box img{
    width:400px;
    margin-right:80px;
}